<template>
  <div class="transport-grant">
    <Title :label="`Анкета «Транспортный грант»/ ${currentLabel}`" icon />
    <div class="transport-grant__header">
      <TopSidebar :tabData="formattedExcellentStudentBar" />
    </div>
    <DocsComponent
      v-show="false"
      :type="'passport'"
      :project="true"
      :current-project="currentProject"
      :category_id="currentCategoryId || currentCategoryData?.info_category.id"
      w
      :without_validations="true"
      @up-docs="(value) => (passportDocs = value)"
    />
    <Comment
      class="transport-grant__comment"
      v-if="currentCategoryData.comment_employee && currentStatusForComment"
      :comment="currentCategoryData.comment_employee"
    />
    <router-view
      :project="true"
      :currentProject="currentProject"
      type="social_need"
    ></router-view>
  </div>
</template>

<script>
import excellentStudentBar from "@/enums/excellentStudentBar";
import TopSidebar from "@/components/Blocks/TopSidebar";
import Title from "@/components/Blocks/Title";
import Comment from "@/components/Blocks/Comment";
import { mapGetters, mapState } from "vuex";
import DocsComponent from "@/components/Blocks/DocsComponent";

export default {
  name: "ExcellentStudentForm",
  components: { DocsComponent, Comment, Title, TopSidebar },
  data() {
    return {
      passportDocs: [],
      excellentStudentBar,
      commentStatuses: [27, 28, 35, 40],
    };
  },
  watch: {
    user() {
      this.setProjectFilledData();
    },
    disabledFields: {
      handler: function () {
        this.setProjectFilledData();
      },
      deep: true,
    },
  },
  computed: {
    currentProject() {
      let sortedArr = JSON.parse(
        JSON.stringify(Object.values(this.projectsList))
      );
      sortedArr = sortedArr?.find((item) => {
        return this.currentCategoryProject
          ? item.id === this.currentCategoryProject
          : item.id === this.currentCategoryData?.info_project?.id;
      });

      return sortedArr;
    },
    currentLabel() {
      return this.currentProject?.settings?.categories.find((item) => {
        return this.currentCategoryId
          ? item.category_id === this.currentCategoryId
          : item.category_id === this.currentCategoryData?.info_category?.id;
      })?.title;
    },
    formattedExcellentStudentBar() {
      let buffer = JSON.parse(JSON.stringify(this.excellentStudentBar));

      return buffer.map((item) => {
        if (item.key === "personal") {
          item.disabled = !this.disabledFields["isProjectProfileFilled"];
        }
        if (item.key === "performance") {
          item.disabled =
            !this.disabledFields["isProjectProfileFilled"] ||
            !this.disabledFields["isProjectPersonalFilled"];
        }
        return item;
      });
    },
    currentStatusForComment() {
      return this.currentCategoryData?.status?.key
        ? this.commentStatuses.includes(this.currentCategoryData?.status?.key)
        : false;
    },
    ...mapGetters(["projectsList"]),
    ...mapState("user", ["disabledFields", "user"]),
    ...mapState([
      "currentCategoryData",
      "currentCategoryProject",
      "currentCategoryVersion",
      "currentCategoryId",
    ]),
  },
  methods: {
    async setProjectFilledData() {
      await this.$store.dispatch("user/checkProjectValidation", [
        this.passportDocs
          .filter((doc) => {
            return doc.is_required;
          })
          .map((item) => {
            return item.key_document;
          }),
        "isProjectPersonalFilled",
        "passport",
        this.disabledFields["isCitizenRussia"]
          ? ["series", "division_code"]
          : ["migration_card_series", "migration_card_number"],
      ]);
    },
  },
  mounted() {
    if (this.user) {
      this.setProjectFilledData();
    }
    this.$store.commit("setNominationValue", [
      "isOldApplication",
      this.currentCategoryData?.version
        ? this.currentProject.version !== this.currentCategoryData?.version
        : this.currentProject.version !== this.currentCategoryVersion,
    ]);
  },
};
</script>

<style lang="scss">
.transport-grant {
  &__comment {
    margin-bottom: 24px;
    @include adaptive(tablet-big) {
      margin-bottom: 15px;
    }
  }
  &__header {
    margin-bottom: 40px;
    @include adaptive(tablet-big) {
      margin-bottom: 30px;
    }
    @include adaptive(phone) {
      margin-bottom: 20px;
    }
  }
}
</style>
